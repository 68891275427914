section.structs-list {
  display: flex;
  flex-flow: row nowrap;
  .main {
    flex-grow: 1;
    padding-right: 10px;
    overflow: auto;
    .table-container {
      max-height: calc(100vh - 220px);
      overflow: auto;
    }
    .selected {
      background: #3f51b5;
      * {
        color: #fafafa;
      }
    }
  }
  .aside {
    width: 500px;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 10px 10px 10px;
    border-left: 1px solid #e5e5e5;
    .table-container {
      max-height: calc(100vh - 130px);
      overflow: auto;
      td {
        text-align: center;
        padding: 5px;
        &:last-child {
          width: 0px !important;
          min-width: 0px;
        }
      }
      .input-sm {
        width: 50px;
        padding: 0px;
        box-sizing: border-box;
        text-align: center;
      }
      input {
        box-sizing: border-box;
        margin: 0;
      }
    }
    .addition-form {
      display: flex;
      margin: 1rem 0;
      border: 1px solid rgba(224, 224, 224, 1);
      padding: 0.25rem;
      box-sizing: border-box;
      input,
      select {
        flex-grow: 1;
        padding: 10px 3px;
        background: transparent;
        border: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }
}
.categories__addition-form {
  display: flex;
  > * {
    flex-grow: 1;
    margin-right: 10px;
  }
  button {
    max-width: 100px;
    margin-right: 0;
  }
}
.struct-setting {
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 0.25rem;
  box-sizing: border-box;
  margin: 0.25rem 0;
  table {
    border-collapse: collapse;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8125rem;
    font-weight: 400;
    width: 100%;
    tbody {
      height: auto;
      min-height: 32px;
      display: block;
    }
    th,
    td {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 4px 56px 4px 24px;
      input {
        border: 1px solid rgba(224, 224, 224, 1);
        padding: 10px 5px;
      }
      &::last-child {
        width: 100px;
      }
    }
  }
}
