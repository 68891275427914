html {
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-weight: normal;
  }
  border-bottom: 1px solid #eee;
}

.active-router-link span {
  color: #3f51b5 !important;
}

.text-center {
  text-align: center !important;
}

.struct-settings-table {
  table-layout: fixed;
  .input {
    &-id {
      width: 10px;
      padding: 10px 5px;
      text-align: center;
    }
    &-cell {
      input {
        border: 1px solid #eee;
        padding: 10px;
        width: 100%;
      }
    }
    &-action {
      width: 100px;
      text-align: center;
    }
  }
  .addition-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    input {
      padding: 10px;
      box-sizing: border-box;
      flex-grow: 1;
      border: 1px solid #eee;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
}

.p-0 {
  padding: 0 !important;
}

section.view.loading {
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url('./assets/img/loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 1000;
  }
}
